@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  // background-color: #f4f5fa;
}

@font-face {
  font-family: logomark;
  src: url("/assets/fonts/gill-sans-ultra-bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Nexa Heavy';
  src: url('/assets/fonts/Nexa-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");

.emoji {
  font-family: "Noto Color Emoji", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.frame {
  @apply p-5;
}

button,
[mat-flat-button] {
  border-radius: 6px !important;
}

.text-light {
  color: #555;
}

.text-link{
  @apply text-blue-600 underline hover:cursor-pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0 !important;
}

:root {
  --bg-color: #f4f5fa;
  --mat-sidenav-container-background-color: var(--bg-color);
  --mat-toolbar-container-background-color: var(--bg-color);
  --mat-sidenav-content-background-color: var(--bg-color);

  --sidenav-width: 250px;

  --yellow: #ffb400;
  --green: #56ca00;
  --orange: #ff9900;
  --blue: #16b1ff;
  --red: #ff4c51;
  --purple: #9155fd;
  --primary: #027a95;
}

.bg-default {
  background-color: var(--bg-color);
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-form-field.small {
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-vertical-padding: 8px;
}
.mat-mdc-cell,
.mat-mdc-header-cell {
  --mat-table-row-item-outline-color: #74777f59;
}

.mat-mdc-dialog-surface {
  border-radius: 0.5rem !important;
}

.rounded .mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 28px !important;
}

.card {
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: #fff;
  // box-shadow: 0 2px 10px #3a35411a;
  box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));

  &-clickable {
    &:hover {
      box-shadow: 0 2px 10px #3a354145;
    }
  }
}

$md-breakpoint: 768px;

.table {
  background-color: #fff !important;
  &.md-border-0 {
    @media (min-width: $md-breakpoint) {
      .mat-mdc-cell,
      .mat-mdc-header-cell {
        border-width: 0 !important;
      }
    }
  }

  @extend .card;
  mat-icon {
    vertical-align: middle;
    margin-right: 0.25rem;
  }

  tbody > tr:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }

  :is(td, th) {
    &:first-of-type {
      padding-left: 1.25rem;
      .mat-icon {
        margin-left: -3px;
      }
    }
  }
  th {
    .mat-icon {
      vertical-align: -25%;
    }
  }
}

.rounded-default {
  border-radius: 4px!important;
}

mat-paginator {
  background-color: transparent !important;
}

.mat-mdc-paginator-range-label {
  white-space: nowrap;
}

@keyframes slide-in {
  0% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.slide-in {
  position: relative;
  animation: slide-in 0.5s ease-out forwards;
}

// Disable text selection on mobile
@media (max-width: $md-breakpoint) {
  body {
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
  }
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}

.status-dot{
  height: 0.5rem;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: #56ca00;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;

  &.status-dot-1{
    background-color: rgb(178, 178, 178);
  }
  &.status-dot-2{
    background-color: rgb(0, 122, 204);
  }
  &.status-dot-3{
    background-color: rgb(51, 153, 51);
  }
  &.status-dot-4{
    background-color: var(--orange);
  }
  &.status-dot-5{
    background-color: var(--red);
  }
}

.no-padding {
 .mat-mdc-form-field-subscript-wrapper{
  display: none!important;
  }
}

@media (min-width: $md-breakpoint) {
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  *:hover >
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .1);
    
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, .2);
  }
}